import React from "react";
import { BlitzPage } from "blitz";
import { Box, Text, Image, Flex } from "@chakra-ui/react";
import { Link } from "chakra-next-link";

import { Layout } from "app/core/layouts/Layout";
import { useCurrentUser } from "app/core/hooks/useCurrentUser";

const Home: BlitzPage = () => {
  const currentUser = useCurrentUser();

  if (currentUser) {
    return (
      <Box maxW="80ch" marginX="auto" mt="4" marginBottom="-100">
        <Text>
          Poštovane kolegice i kolege
          <br />
          <br />
          Pred vama je aplikacija za edukaciju liječnika iz područja kojima se bavi naša sekcija.
          Ima nekoliko tematskih cjelina. Krećemo od HIV infekcije i svega onoga što ide uz nju
          (prevencija same HIV infekcije, komorbiditeti, druge spolne bolesti) a obrađujemo i
          infekcije kod imunokompromitiranih bolesnika bilo da su zaraženi HIV-om ili imaju neki
          drugi razlog oslabljenog imuniteta.
          <br />
          <br />
          Pristup svim temama je osim referiranja na službenu literaturu, smjernice, rezultate
          istraživanja začinjen našim vlastitim iskustvom i praktičnim savjetima i prilagođen je za
          liječnike koji se bave ovim problematikama u Republici Hrvatskoj, iako se nadamo da će
          neke stvari biti interesantne i relevantne i za širu regiju.
        </Text>

        <Flex w="full" justify="space-between" align="center" mt="4">
          <Image src="/hdib.jpg" aria-label="logo drustva" height="100px" />

          <Text fontWeight="bold" w="40%">
            Sekcije za zarazu HIV-om i infekcije imunokompromitiranih bolesnika
          </Text>
        </Flex>
      </Box>
    );
  } else {
    return (
      <>
        <Link href="/signup">Sign up</Link>
        <Link href="/login">Login</Link>
      </>
    );
  }
};

Home.suppressFirstRenderFlicker = true;
Home.authenticate = {
  redirectTo: "/login"
};
Home.getLayout = (page) => <Layout title="Home">{page}</Layout>;

export default Home;
