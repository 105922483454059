import React from "react";

export interface ConditionalWrapProps {
  val: boolean | undefined | null | number | string;
  Wrap: (children: React.ReactNode | null | undefined) => JSX.Element | null;
}

export const ConditionalWrap: React.FC<ConditionalWrapProps> = ({ val, Wrap, children }) => {
  if (!!val) {
    return Wrap(children);
  }

  return <>{children}</>;
};
