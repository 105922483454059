import React from "react";
import { Heading, Box, useColorModeValue } from "@chakra-ui/react";
import { Link } from "chakra-next-link";

interface LogoProps {}

export const Logo: React.FC<LogoProps> = () => {
  const color = useColorModeValue("red.500", "red.400");

  return (
    <Link href="/" noUnderline>
      <Heading d="block">
        Cro{" "}
        <Box as="span" color={color}>
          hiv
        </Box>
        ID
      </Heading>
    </Link>
  );
};
